import axios from "axios";
import { getAllMetadataFailure, getAllMetadataRequest, getAllMetadataSuccess } from "../Slices/metaDataSclice";
import { BASE_URL } from "../constant/Url";

export const getMetaDataCreate = async (dispatch) => {
  try {
    dispatch(getAllMetadataRequest());
    const { data } = await axios.get(`${BASE_URL}/meta-data/Getmetadata`);
    dispatch(getAllMetadataSuccess(data));
  } catch (error) {
    if (error.message == "Network Error") {
      return dispatch(getAllMetadataFailure((error.message)))
    }
    dispatch(getAllMetadataFailure(error.response.data.message));
  }
};



